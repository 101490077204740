<template>
  <v-card flat class="mx-1">
    <v-row :class="$vuetify.breakpoint.xs ? 'pr-1 pt-1' : ''" class="my-1 lime lighten-4 justify-center pl-2">
      <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-12 row no-gutters py-0 d-flex justify-center align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Номер:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-tooltip top v-if="order.partner">
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" color="blue lighten-4" depressed @click="route(order.id)" small>
                <div class="font-weight-bold caption">№ {{ order.number }}</div>
              </v-btn>
            </template>
            <span>от Партнера</span>
          </v-tooltip>
          <v-btn v-else-if="order.delivery_price === 0" color="orange" depressed @click="route(order.id)" small>
            <div class="font-weight-bold caption">№ {{ order.number }}</div>
          </v-btn>
          <v-btn v-else color="lime lighten-3" depressed @click="route(order.id)" small>
            <div class="font-weight-bold caption">№ {{ order.number }}</div>
          </v-btn>
        </v-col>
      </v-col>
      <v-col cols="12"
             class="col col-lg-2 col-md-2 col-sm-2 row no-gutters py-0 d-flex justify-center justify-lg-start justify-md-start justify-sm-start align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Тел.:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center">
          <v-btn :class="$vuetify.breakpoint.xs ? 'mr-1' : ''" color="lime lighten-3" depressed label x-small
                 @click="call(order.client.phone)">
            <v-icon small color="primary">mdi-phone-outgoing</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on,attrs }">
              <v-btn color="lime lighten-3" depressed v-bind="attrs" v-on="on" small label @click="setClient(order)">
                <div class="font-weight-bold">{{ order.client.phone | normalizePhone }}</div>
              </v-btn>
            </template>
            <v-col>
              <span class="row justify-center" v-if="order.client.name">{{ order.client.name }}</span>
              <span class="row justify-center" v-else>Нет имени</span>
              <span class="row justify-center">{{ order.address }} {{ order.description }}</span>
              <span class="row justify-center">{{ order.store_comment }} {{ order.driver_comment }}</span>
            </v-col>
          </v-tooltip>
        </v-col>
      </v-col>
      <v-col cols="12" class="col col-lg-3 col-md-3 col-sm-12 row no-gutters py-0 d-flex justify-center align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Заведение:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on,attrs }">
              <span v-bind="attrs" v-on="on" :class="$vuetify.breakpoint.xs ? 'text-end' : ''"
                    class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center text-body-2 font-weight-bold">{{
                  order.store.name
                }}</span>
              </template>
              <v-col>
                <span class="row justify-center">{{ order.store.address }}</span>
                <span class="row justify-center">{{ order.store_comment }}</span>
              </v-col>
            </v-tooltip>
            <v-row no-gutters
                   class="justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap d-flex align-center">
              <div v-for="phone in formatPhones(order.store.phone)" :key="phone">
                <v-btn class="mx-1" color="lime lighten-3" depressed label x-small @click="call(phone)">
                  <v-icon small color="primary">mdi-phone-outgoing</v-icon>
                </v-btn>
                <span :class="$vuetify.breakpoint.xs ? '' : 'mr-2'">{{ phone | normalizePhone }}</span>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 py-0 d-flex row no-gutters justify-center align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Курьер:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on,attrs }">
              <span v-bind="attrs" v-on="on" :class="$vuetify.breakpoint.xs ? 'text-end' : ''"
                    class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center text-body-2 font-weight-bold">{{
                  order.driver.name
                }}</span>
              </template>
              <v-col v-if="order.driver_comment">
                <span class="row justify-center">{{ order.driver_comment }}</span>
              </v-col>
            </v-tooltip>
            <v-row no-gutters
                   class="justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap d-flex align-center">
              <div v-for="phone in formatPhones(order.driver.phone)" :key="phone">
                <v-btn class="mx-1" color="lime lighten-3" depressed label x-small @click="call(phone)">
                  <v-icon small color="primary">mdi-phone-outgoing</v-icon>
                </v-btn>
                <span :class="$vuetify.breakpoint.xs ? '' : 'mr-2'">{{ phone | normalizePhone }}</span>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-col>
      <v-col cols="12"
             class="col col-lg-4 col-md-4 col-sm-12 row no-gutters py-0 d-flex justify-space-around align-center">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Время:</span>
        </v-col>
        <v-col cols="8"
               class="col col-lg-8 col-md-8 col-sm-8 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <v-chip-group column>
            <v-chip color="primary" label small>
              <v-icon left>mdi-clock-start</v-icon>
              {{ order.started_at |timeFormat }}
            </v-chip>
            <v-chip color="blue" label small :class="$vuetify.breakpoint.xs ? 'mr-0' : ''">
              <v-icon left>mdi-clock-start</v-icon>
              {{ order.finished_at | timeFormat }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pb-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Сумма:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pb-1' : ''"
               class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center text-body-2">
          <v-tooltip top>
            <template v-slot:activator="{ on,attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon color="blue" class="mr-1" v-if="order.discount > 0">mdi-sale</v-icon>
                <v-icon class="mr-1" v-if="order.payment_system === 'NONE'" color="primary">mdi-cash</v-icon>
                <v-icon class="mr-1" v-else-if="order.payment_system === 'PAYBOX'" color="orange">mdi-credit-card
                </v-icon>
                <v-icon class="mr-1" v-else-if="order.payment_system === 'ODENGI'" color="orange">
                  mdi-alpha-o-circle-outline
                </v-icon>
                <v-icon class="mr-1" v-else-if="order.payment_system === 'BALANCE'" color="orange">
                  mdi-alpha-b-circle-outline
                </v-icon>
                <span class="ml-1">{{ order.total_price - order.discount }} c.</span>
              </div>
            </template>
            <v-row no-gutters>
              <div v-if="order.store_price">{{ order.store_price }} c.</div>
              <div v-else>0 c.</div>
              <div v-if="order.discount && order.discount > 0">- {{ order.discount }} c.</div>
              <div v-if="order.store_delivery_price && order.store_delivery_price > 0">+ {{
                  order.store_delivery_price
                }} c.
              </div>
              <div class="ml-1">+ {{ order.delivery_price }} c.</div>
              <div class="ml-1">= {{ order.total_price - order.discount }} c.</div>
            </v-row>
          </v-tooltip>
        </v-col>
      </v-col>
    </v-row>
    <v-divider class="hidden-sm-and-up my-3"></v-divider>
    <v-dialog v-model="dialog2" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
      <history :order="order"></history>
    </v-dialog>
  </v-card>
</template>


<script>
import format from 'date-fns/format';
import history from "../../components/history";
import axios from "@/services/axios";

export default {
  components: {history},
  data: () => ({
    dialog2: false
  }),
  props: {
    order: {
      type: Object
    }
  },
  methods: {
    call(tel) {
      tel = tel.replaceAll("+", "")
      if (tel.length === 12) {
        let pho = tel.substr(3, tel.length)
        let code = pho.substr(0, 3)
        let number = pho.substr(3, pho.length)
        tel = 'http://192.168.3.14/cgi-bin/api-make_call?passcode=admin&hs=3&phonenumber=' + '0' + code + number;
      } else {
        tel = 'http://192.168.3.14/cgi-bin/api-make_call?passcode=admin&hs=3&phonenumber=' + tel;
      }
      axios.send({
        url: tel,
        method: "GET",
      })
    },
    formatPhones(tel) {
      if (tel) {
        return tel.split(",")
      } else {
        return ""
      }
    },
    setClient(v) {
      this.order = v
      this.dialog2 = true
    },
    route(id) {
      this.$router.push({name: 'finished_order', params: {id: id}})
    },
  },
  filters: {
    timeFormat(d) {
      return format(new Date(d), "HH:mm")
    },
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = ""
      let phones = p.split(",")

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    }
  }
}
</script>

<template>
  <div>
    <v-form v-show="show" ref="form" lazy-validation class="my-3">
      <v-row no-gutters>
        <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-12 row no-gutters justify-center d-flex align-center">
          <v-card shaped elevation="10" :class="$vuetify.breakpoint.xs ? 'pt-4' : 'pt-7'">
            <v-row no-gutters class="justify-space-around d-flex align-center">
              <span :class="$vuetify.breakpoint.xs ? 'text-h6 pb-2' : 'text-h5'">Создать новый заказ</span>
              <v-radio-group class="ma-0 pa-0" row hide-details v-model="order.partner">
                <v-radio
                    label="От партнера"
                    :value="true"/>
                <v-radio
                    label="От клиента"
                    :value="false"/>
              </v-radio-group>
            </v-row>
            <v-row no-gutters :class="$vuetify.breakpoint.xs ? 'my-2' : 'my-6'" class="mx-4">
              <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6">
                <v-col cols="12">
                  <v-text-field v-model="order.phone"
                                :counter="9"
                                :error-messages="namePhoneErrors"
                                prefix="+ 996"
                                dense
                                outlined
                                :hide-details="$vuetify.breakpoint.xsOnly"
                                rounded
                                label="Введите тел. (прим: 555 123 456)"
                                prepend-inner-icon="mdi-cellphone"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="address"
                                disabled
                                dense
                                outlined
                                rounded
                                :hide-details="$vuetify.breakpoint.xsOnly"
                                label="Введите адрес на карте"
                                prepend-inner-icon="mdi-home"
                                required/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="order.description"
                                dense
                                outlined
                                rounded
                                :hide-details="$vuetify.breakpoint.xsOnly"
                                label="Дом: Подъезд: Этаж: Квартира (Офис):"
                                prepend-inner-icon="mdi-city"
                                required/>
                </v-col>
                <v-col cols="12" class="row no-gutters d-flex align-center">
                  <v-col cols="6" class="pr-1">
                    <v-text-field v-model="position.lat"
                                  disabled
                                  dense
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  outlined
                                  rounded
                                  label="lat"
                                  prepend-inner-icon="mdi-map-marker"
                                  required/>
                  </v-col>
                  <v-col cols="6" class="pl-1">
                    <v-text-field v-model="position.lng"
                                  disabled
                                  dense
                                  outlined
                                  rounded
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  label="lng"
                                  prepend-inner-icon="mdi-map-marker"
                                  required/>
                  </v-col>
                </v-col>
                <v-col cols="12" class="row no-gutters d-flex align-center">
                  <v-text-field v-model="order.store_number"
                                dense
                                outlined
                                rounded
                                :hide-details="$vuetify.breakpoint.xsOnly"
                                label="Номер заказа от заведения:"/>
                </v-col>
              </v-col>
              <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6">
                <v-col cols="12">
                  <v-autocomplete v-model="store"
                                  :items="stores"
                                  item-text="name"
                                  item-value="id"
                                  return-object
                                  :error-messages="nameStoreErrors"
                                  dense
                                  outlined
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  rounded
                                  clearable
                                  label="Выберите заведение"
                                  prepend-inner-icon="mdi-silverware"
                                  @change="fetchRules"/>
                </v-col>
                <v-col v-if="address && store" cols="12" class="row no-gutters d-flex align-center">
                  <v-autocomplete v-model="selectedRule"
                                  :items="filteredRules"
                                  item-text="name"
                                  item-value="id"
                                  return-object
                                  :error-messages="nameRuleErrors"
                                  dense
                                  outlined
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  rounded
                                  label="Выберите правило"
                                  prepend-inner-icon="mdi-order-bool-ascending"
                                  required/>
                </v-col>
                <v-col cols="12" class="row no-gutters d-flex align-center">
                  <v-col cols="6" class="row no-gutters justify-center d-flex align-center pr-1">
                    <v-text-field v-model="order.store_price"
                                  dense
                                  outlined
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  rounded
                                  label="Цена от заведения"
                                  prepend-inner-icon="mdi-silverware-fork-knife"
                                  required/>
                  </v-col>
                  <v-col cols="6" class="row no-gutters d-flex align-center pl-1">
                    <v-text-field v-model="order.store_delivery_price"
                                  dense
                                  outlined
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  rounded
                                  label="Доставка от заведения"
                                  prepend-inner-icon="mdi-moped"
                                  required/>
                  </v-col>
                </v-col>
                <v-col cols="12" class="row no-gutters d-flex align-center">
                  <v-col cols="6" class="row no-gutters justify-center d-flex align-center pr-1">
                    <div class="pb-5">
                      <span v-if="position.lat && position.lng && store.lat && store.lng" class="text-body-2">Расстояние:</span>
                      <span v-if="position.lat && position.lng && store.lat && store.lng"
                            class="font-weight-bold text-body-2">{{ distance }} км.</span>
                    </div>
                  </v-col>
                  <v-col cols="6" class="pl-1">
                    <v-text-field v-model="selectedRule.delivery_price"
                                  disabled
                                  dense
                                  outlined
                                  rounded
                                  :hide-details="$vuetify.breakpoint.xsOnly"
                                  label="Доставка"
                                  prepend-inner-icon="mdi-cash"
                                  required/>
                  </v-col>
                </v-col>
                <v-col cols="12" class="row no-gutters justify-space-around">
                  <v-btn small :disabled="$v.order.phone.$invalid === true ||
                  $v.store.$invalid === true ||
                  $v.selectedRule.name.$invalid === true ||
                  $v.order.phone.minLength.$invalid === true ||
                  $v.order.phone.maxLength.$invalid === true"
                         rounded color="primary" class="mr-4 mt-2" @click.prevent="validate">
                    Оформить
                  </v-btn>
                  <v-btn small dark rounded color="error" class="mr-4 mt-2" @click="reset">
                    Очистить
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-12 row no-gutters justify-center d-flex align-center">
          <div class="map">
            <l-map :zoom="zoom" ref="map"
                   :center="[position.lat || userLocation.lat || defaultLocation.lat, position.lng || userLocation.lng || defaultLocation.lng]"
                   @dblclick="onMapClick">
              <l-geosearch :options="geoSearchOptions"></l-geosearch>
              <l-tile-layer :url="url" :attribution="attribution"/>
              <l-marker v-if="position.lat && position.lng"
                        visible
                        :draggable="true"
                        :icon="icon"
                        :lat-lng.sync="position"
                        @dragstart="dragging = true"
                        @dragend="dragging = false">
                <l-tooltip :content="tooltipContent" :options="{ permanent: true }"/>
              </l-marker>
            </l-map>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "@/services/axios";
import {LMap, LMarker, LTileLayer, LTooltip} from "vue2-leaflet";
import {icon} from "leaflet";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import {validationMixin} from 'vuelidate';
import {maxLength, minLength, required} from 'vuelidate/lib/validators';
import {uuid} from "uuidv4";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeosearch
  },

  mixins: [validationMixin],

  data: () => ({
    show: true,
    rules: [],
    store: {},
    selectedRule: {},
    loading: false,
    geoSearchOptions: {
      provider: new OpenStreetMapProvider({
        params: {
          countrycodes: "kg",
        }
      }),
      showMarker: false,
      autoClose: true
    },
    defaultLocation: {lat: 42.869717, lng: 74.607213},
    userLocation: {},
    icon: icon({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    }),
    position: {},
    address: "",
    dragging: false,
    zoom: 13,
    currentZoom: 15,
    url: 'https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=a4b4d72cdb1c433e803753b11ab6ccb6',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    api_URL: 'https://api.wheretheiss.at/v1/satellites/25544',
    order: {
      phone: '',
      store_id: uuid.Nil,
      address: '',
      description: '',
      driver_price: 0,
      driver_commission: 0,
      lat: '',
      lng: '',
      store_price: 0,
      id: uuid.Nil,
      partner: true,
      store_delivery_price: 0,
      store_number: ''
    },
  }),

  validations: {
    store: {
      required
    },
    selectedRule: {
      name: {required}
    },
    order: {
      phone: {required, minLength: minLength(9), maxLength: maxLength(9)},
    }
  },

  mounted() {
    this.$store.dispatch("stores/fetchPartner");
    this.$store.dispatch("stores/fetchActive");
    this.getIIS();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
  },
  watch: {
    position: {
      deep: true,
      async handler() {
        this.address = await this.getAddress();
      }
    }
  },
  computed: {
    namePhoneErrors() {
      const errors = [];
      if (!this.$v.order.phone.required) errors.push('Заполните поле')
      if (!this.$v.order.phone.minLength) errors.push('Формат')
      if (!this.$v.order.phone.maxLength) errors.push('Формат1')

      return errors;
    },
    nameStoreErrors() {
      const errors = [];
      if (!this.$v.store.required) errors.push('Выберите заведение')

      return errors;
    },
    nameRuleErrors() {
      const errors = [];
      if (!this.$v.selectedRule.name.required) errors.push('Выберите заведение')

      return errors;
    },
    filteredRules() {
      let distance = this.getDistance(this.order.lat, this.order.lng, this.store.lat, this.store.lng);
      if (this.order.partner) {
        return this.rules.filter(r => r.max_distance >= distance && r.min_distance <= distance && (r.min_price <= this.order.store_price || !r.min_price) && r.partner)
      } else {
        return this.rules.filter(r => r.max_distance >= distance && r.min_distance <= distance && (r.min_price <= this.order.store_price || !r.min_price) && !r.partner)
      }
    },
    distance() {
      let d = this.getDistance(this.order.lat, this.order.lng, this.store.lat, this.store.lng);
      return d.toFixed(2);
    },
    stores() {
      if (this.order.partner) {
        return this.$store.getters["stores/partner"];
      } else {
        return this.$store.getters["stores/active"];
      }
    },
    size() {
      return ((this.currentZoom - this.zoom) > 1 ? (this.currentZoom - this.zoom) : 1) * 30;
    },
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address}</strong> <hr/><strong>lat:</strong> ${this.position.lat}<br/> <strong>lng:</strong> ${this.position.lng}`;
    }
  },

  methods: {
    getDistance(lat1, lon1, lat2, lon2) {
      let R = 6371; // Radius of the earth in km
      let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      let dLon = this.deg2rad(lon2 - lon1);
      let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    fetchRules() {
      if (this.store && this.store.id) {
        axios.send({
          url: "/rules/" + this.store.id,
          method: "GET"
        }).then(resp => {
          this.rules = resp.data;
        })
      }
    },
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const {lat, lng} = this.position;
        this.order.lat = this.position.lat;
        this.order.lng = this.position.lng;
        const result = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          let ad = body.display_name;
          let adr = ad.split(",")
          address = adr.slice(0, 2);
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = {lat: loc.y, lng: loc.x};
    },
    async getIIS() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          this.userLocation = {
            lat: pos.coords.latitude, lng: pos.coords.longitude
          }
        })
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    validate() {
      this.$refs.form.validate();
      this.order.store_price = parseFloat(this.order.store_price);
      this.order.driver_price = parseFloat(this.order.driver_price);
      this.order.driver_commission = parseFloat(this.order.driver_commission);
      this.order.lat = parseFloat(this.order.lat);
      this.order.lng = parseFloat(this.order.lng);
      this.order.address = this.address.toString();
      this.order.phone = "996" + this.order.phone;
      this.order.driver_commission = parseFloat(this.selectedRule.commission);
      this.order.driver_price = parseFloat(this.selectedRule.price);
      this.order.store_delivery_price = parseFloat(this.order.store_delivery_price);
      this.order.store_id = this.store.id;

      axios.send({
        url: "/orders/new",
        method: "POST",
        data: this.order
      }).then((resp) => {
        this.order.id = resp.data.id;
        this.show = false;
        this.$router.go();
      });
    },
    reset() {
      this.order.phone = '';
      this.order.store_id = '';
      this.order.address = '';
      this.order.description = '';
      this.order.driver_price = 0;
      this.order.driver_commission = 0;
      this.order.lat = '';
      this.order.lng = '';
      this.order.store_price = 0;
      this.order.id = '';
      this.store = {};
      this.selectedRule = {};
      this.order.store_number = '';
    }
  }
}
</script>

<style scoped>
.map {
  height: 170%;
  width: 100%;
  margin-left: 10px;
}

@media (max-width: 959px) {
  .map {
    margin-left: 0;
    margin-bottom: 650px;
    margin-top: 10px;
    height: 100%;
  }
}
</style>

<template>
  <v-card class="mx-1" flat>
    <v-row :class="$vuetify.breakpoint.xs ? 'pa-1' : ''" class="my-1 grey lighten-5 justify-center">
      <v-col cols="12" class="row no-gutters col col-lg-2 col-md-2 col-sm-2
      py-0 d-flex justify-center align-center justify-lg-space-around justify-md-space-around justify-sm-space-around">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span v-if="order.started_at && !order.confirmed_at && order.confirm_reason"
                class="caption">Подтверждено:</span>
          <span v-else class="caption">Начало:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-tooltip top v-if="order.started_at && !order.confirmed_at && order.confirm_reason">
            <template v-slot:activator="{ on,attrs }">
              <v-chip v-if="order.confirm_reason === 'Первый заказ'" v-bind="attrs" v-on="on" color="yellow" label small>
                {{ order.started_at | timeFormat }}
              </v-chip>
              <v-chip v-else v-bind="attrs" v-on="on" color="pink" label small>
                {{ order.started_at | timeFormat }}
              </v-chip>
            </template>
            <span>{{ order.confirm_reason }}</span>
          </v-tooltip>
          <v-chip v-else color="primary" label small>
            {{ order.started_at | timeFormat }}
          </v-chip>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Номер:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-tooltip top v-if="order.partner">
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" color="blue lighten-3" depressed small @click="route(order.id)">
                <div class="font-weight-bold caption">№ {{ order.number }}</div>
              </v-btn>
            </template>
            <span>от Партнера</span>
          </v-tooltip>
          <v-btn v-else-if="order.delivery_price === 0" color="orange" depressed @click="route(order.id)" small>
            <div class="font-weight-bold caption">№ {{ order.number }}</div>
          </v-btn>
          <v-btn v-else color="grey lighten-3" depressed small @click="route(order.id)">
            <div class="font-weight-bold caption">№ {{ order.number }}</div>
          </v-btn>
        </v-col>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'py-1' : 'py-0'" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 d-flex justify-center align-center">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Тел.:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center">
          <v-btn :class="$vuetify.breakpoint.xs ? 'mr-1' : ''" color="grey lighten-3" depressed label x-small @click="call(order.client.phone)">
            <v-icon small color="primary">mdi-phone-outgoing</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on,attrs }">
              <v-btn v-bind="attrs" v-on="on" color="grey lighten-3" depressed label small @click="setClient(order)">
                <div class="font-weight-bold">{{ order.client.phone | normalizePhone }}</div>
              </v-btn>
            </template>
            <v-col>
              <span v-if="order.client.name" class="row justify-center">{{ order.client.name }}</span>
              <span v-else class="row justify-center">Нет имени</span>
              <span class="row justify-center">{{ order.address }} {{ order.description }}</span>
              <span class="row justify-center">{{ order.store_comment }} {{ order.driver_comment }}</span>
            </v-col>
          </v-tooltip>
        </v-col>
      </v-col>
      <v-col cols="12" class="row no-gutters col col-lg-4 col-md-4 col-sm-4 py-0 d-flex justify-start align-center">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span v-if="order.store_finished_at" class="caption">Приготовлено:</span>
          <span v-else-if="order.store_started_at" class="caption">Готовится:</span>
          <span v-else class="caption">Ожидает:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <v-chip v-if="order.store_finished_at" color="blue" label small>
            {{ order.store_finished_at | timeFormat }}
          </v-chip>
          <v-chip v-else-if="order.store_started_at" color="primary" label small>
            {{ order.store_started_at | timeFormat }}
          </v-chip>
          <v-chip v-else color="grey" label small>
            <span class="ml-1">-- : --</span>
          </v-chip>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Заведение:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-10 col-md-10 col-sm-12">
          <v-tooltip top>
            <template v-slot:activator="{ on,attrs }">
              <span :class="$vuetify.breakpoint.xs ? 'text-end' : ''"
                    class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center body-2 font-weight-bold"
                    v-bind="attrs" v-on="on">
                {{ order.store.name }}
              </span>
            </template>
            <v-col>
              <span class="row justify-center">{{ order.store.address }}</span>
              <span class="row justify-center">{{ order.store_comment }}</span>
              <span class="row justify-center">{{ order.store.phone | normalizePhone }}</span>
            </v-col>
          </v-tooltip>
          <v-row no-gutters class="justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap d-flex align-center">
           <div v-for="phone in formatPhones(order.store.phone)" :key="phone">
             <v-btn class="mx-1" color="grey lighten-3" depressed label x-small @click="call(phone)">
               <v-icon small color="primary">mdi-phone-outgoing</v-icon>
             </v-btn>
             <span :class="$vuetify.breakpoint.xs ? '' : 'mr-2'">{{ phone | normalizePhone }}</span>
           </div>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" class="row no-gutters col col-lg-3 col-md-3 col-sm-3 py-0 d-flex justify-center align-center">
        <v-col :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" cols="4" class="row no-gutters hidden-sm-and-up">
          <span v-if="order.driver_started_at" class="caption">В пути:</span>
          <span v-else-if="order.driver_confirmed_at" class="caption">Подтвердил:</span>
        </v-col>
        <v-col :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" cols="8"
               class="col col-lg-3 col-md-3 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <v-chip v-if="order.driver_started_at" color="blue" label small>
            <span class="text--white">{{ order.driver_started_at | timeFormat }}</span>
          </v-chip>
          <v-chip v-else-if="order.driver_confirmed_at" color="primary" label small>
            {{ order.driver_confirmed_at | timeFormat }}
          </v-chip>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span v-if="order.driver" class="caption">Курьер:</span>
          <span v-else-if="items.length" class="caption">Отправлено:</span>
        </v-col>
        <v-col cols="8" v-if="order.driver" class="col col-lg-9 col-md-9 col-sm-12 row no-gutters">
          <v-col cols="12" class="row no-gutters">
            <v-tooltip top>
              <template v-slot:activator="{ on,attrs }">
                <v-col class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center" v-bind="attrs" v-on="on">
                  <span class="text-body-2 font-weight-bold">{{ order.driver.name }}</span>
                </v-col>
              </template>
              <v-col>
                <span class="row justify-center">{{ order.driver_comment }}</span>
              </v-col>
            </v-tooltip>
          </v-col>
          <v-col cols="12"
                 class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center caption text-no-wrap d-flex align-center">
            <div v-for="phone in formatPhones(order.driver.phone)" :key="phone">
              <v-btn class="mx-1" color="grey lighten-3" depressed label x-small @click="call(phone)">
                <v-icon small color="primary">mdi-phone-outgoing</v-icon>
              </v-btn>
              <span :class="$vuetify.breakpoint.xs ? '' : 'mr-2'">{{ phone | normalizePhone }}</span>
            </div>
          </v-col>
        </v-col>
        <v-col cols="8" v-else class="col col-lg-9 col-md-9 col-sm-12">
          <v-row no-gutters v-for="driver in items" :key="driver.id"
                 class="justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
            <div class="text-no-wrap caption">{{ driver.driver_name }}</div>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" class="row no-gutters col col-lg-1 col-md-1 col-sm-1
      py-0 d-flex justify-end align-center body-2 justify-lg-center justify-md-center justify-sm-center">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Сумма:</span>
        </v-col>
        <v-col cols="6"
               class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-icon v-if="order.discount > 0" class="mr-1" color="blue">mdi-sale</v-icon>
          <v-icon class="mr-1" v-if="order.payment_system === 'NONE'" color="primary">mdi-cash</v-icon>
          <v-icon class="mr-1" v-else-if="order.payment_system === 'PAYBOX'" color="orange">mdi-credit-card</v-icon>
          <v-icon class="mr-1" v-else-if="order.payment_system === 'ODENGI'" color="orange">mdi-alpha-o-circle-outline
          </v-icon>
          <v-icon class="mr-1" v-else-if="order.payment_system === 'BALANCE'" color="orange">
            mdi-alpha-b-circle-outline
          </v-icon>
        </v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on,attrs }">
            <v-col v-bind="attrs" v-on="on" cols="2"
                   class="col col-lg-12 col-md-12 col-sm-12 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
              {{ order.total_price - order.discount }} c.
            </v-col>
          </template>
          <v-row no-gutters>
            <div v-if="order.store_price">{{ order.store_price }} c.</div>
            <div v-else>0 c.</div>
            <div v-if="order.discount && order.discount > 0" class="ml-1">- {{ order.discount }} c.</div>
            <div v-if="order.store_delivery_price && order.store_delivery_price > 0">+ {{ order.store_delivery_price }}</div>
            <div class="ml-1">+ {{ order.delivery_price }} c.</div>
            <div class="ml-1">= {{ order.total_price - order.discount }} c.</div>
          </v-row>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-divider class="hidden-sm-and-up my-2"></v-divider>
    <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
      <history :order="order"></history>
    </v-dialog>
  </v-card>
</template>

<script>
import history from "../../components/history";
import axios from "@/services/axios";
import format from 'date-fns/format';

export default {
  components: {history},
  data: () => ({
    dialog: false,
    items: []
  }),
  props: {
    order: {
      type: Object
    }
  },
  mounted() {
    this.searchNotified()
  },
  methods: {
    searchNotified() {
      axios.send({
        url: "/orders/notified/" + this.order.id,
        method: "GET",
      }).then((resp) => {
        this.items = resp.data
      });
    },
    call(tel) {
      tel = tel.replaceAll("+", "")
      if (tel.length === 12) {
        let pho = tel.substr(3, tel.length)
        let code = pho.substr(0, 3)
        let number = pho.substr(3, pho.length)
        tel = 'http://192.168.3.14/cgi-bin/api-make_call?passcode=admin&hs=3&phonenumber=' + '0' + code + number
      } else {
        tel = 'http://192.168.3.14/cgi-bin/api-make_call?passcode=admin&hs=3&phonenumber=' + tel
      }
      axios.send({
        url: tel,
        method: "GET",
      })
    },
    formatPhones(tel) {
      if (tel) {
        return tel.split(",")
      } else {
        return ""
      }
    },
    setClient(v) {
      this.order = v
      this.dialog = true
    },
    route(id) {
      this.$router.push({name: 'active_order', params: {id: id}})
    },
  },
  filters: {
    timeFormat(d) {
      return format(new Date(d), "HH:mm")
    },
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = ""
      let phones = p.split(",")

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    }
  }
}
</script>

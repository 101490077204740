<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="date" scrollable @input="setDate(date)" />
      </v-dialog>
      <v-toolbar-title>{{ date }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-list subheader two-line nav>
      <v-tabs show-arrows v-model="tab" background-color="transparent" color="black" grow>
        <v-tab active-class="font-weight-bold" @change="searchActive">
          <span>Активные: {{ progress.length }}</span>
        </v-tab>
        <v-tab active-class="font-weight-bold" @change="searchFinished">
          <span>Завершенные: {{ finished.length }}</span>
        </v-tab>
        <v-tab active-class="font-weight-bold" @change="searchActive">
          <span v-if="restart.length > 0" class="indigo--text font-weight-bold">Ожидание: {{ restart.length }}</span>
          <span v-else>Ожидание: {{ restart.length }}</span>
        </v-tab>
        <v-tab active-class="font-weight-bold" @change="searchCanceled">
          <span>Отмененные: {{ canceled.length }}</span>
        </v-tab>
        <v-tab active-class="font-weight-bold">
          <span class="text-subtitle-2">Новый заказ:</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div v-for="order in progress" :key="order.id">
            <active :order="order"></active>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-for="order in finished" :key="order.id">
            <finished :order="order"></finished>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-for="order in restart" :key="order.id">
            <active :order="order"></active>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-for="order in canceled" :key="order.id">
            <canceled :order="order"></canceled>
          </div>
        </v-tab-item>
        <v-tab-item>
          <new-order></new-order>
        </v-tab-item>
      </v-tabs-items>
    </v-list>
  </v-card>
</template>

<script>

import axios from "@/services/axios";
import active from "./active";
import finished from "./finished";
import canceled from "./canceled";
import newOrder from "./new";

export default {
  components: { active, finished, canceled,newOrder },
  data: () => ({
    tab: null,
    page: 1,
    date: {},
    modal: false
  }),

  mounted() {
    let date = localStorage.getItem('date');
    if (!date) {
      this.date = new Date().toISOString().substr(0, 10);
    } else {
      this.date = date;
    }
    this.fetchCount();
  },

  computed: {
    active() {
      return this.$store.getters["orders/active"];
    },
    finished() {
      return this.$store.getters["orders/finished"];
    },
    canceled() {
      return this.$store.getters["orders/canceled"];
    },
    restart() {
      return this.active.filter(o => o.store_canceled_at);
    },
    progress() {
      return this.active.filter(o => !o.store_canceled_at);
    }
  },

  methods: {
    setDate(date) {
      this.modal = false;
      this.$refs.dialog.save(date);
      localStorage.setItem("date", date);
      this.fetchCount();
    },
    fetchCount() {
      axios.send({
        url: "/orders?date=" + this.date,
        method: "GET",
      }).then(() => {
        this.searchActive();
        this.searchFinished();
        this.searchCanceled();
      })
    },
    searchActive() {
      this.$store.dispatch("orders/searchActive", this.date);
    },
    searchFinished() {
      this.$store.dispatch("orders/searchFinished", this.date);
    },
    searchCanceled() {
      this.$store.dispatch("orders/searchCanceled", this.date);
    }
  }
}
</script>
